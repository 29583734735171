

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  /* justify-content: center; */
  font-family: 'Raleway', sans-serif;
  min-height: 100vh;
  min-width: 100vw;
  text-transform: lowercase;
  font-size: 62.5%;
  font-weight: 400;
  
}
.header {
  background-color: rgba(230, 241, 244, 255);
  width: 100vw;
}
.main {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  /* justify-content: center; */
  height: 100vh;
  width: 100vw;
}


canvas {
  /* border: 1px solid #A8A8A8; */
  cursor: crosshair;
  
}

.containerBody {
  /* border: 1px dotted purple; */
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  padding-left: 12%;
  /* overflow: hidden; */
  max-height: 70vh;
  justify-content: space-evenly;
  padding-top: 3%;
}
.canvasContainer {
  /* border: 1px solid salmon; */
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 5%;
  animation: fadeIn 3.2s ease-in;
  /* justify-self: center; */
  font-size: 1rem;
}


.swatchList {
  /* border: 1px dashed blue; */
  height: 97%;
  width: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  /* padding-top: 5%; */
  /* background-color: #fffbf7; */

}
.swatch {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: row;
  margin: 0 5%;
  justify-content: space-between;
  align-content: center;
  scrollbar-color: #777;
  animation: fadeIn .35s ease-out;
  /* border: 1px green solid; */
  padding: 0.2rem 0;
  /* border-bottom: 1px dotted rgb(220, 220, 220); */
}

.swatch-square {
  cursor: pointer;
  /* border: 1px blue solid; */
  border-radius: 10px;
  align-self: center;
  /* flex-basis: 1; */
  width: 15%;
  font-size: 1rem;
}

.swatch-text {
  text-align: center;
  /* border: 1px dashed purple; */
  align-self: center;
  /* flex-basis: 3 */
  width: 80%;
  font-size: 1.4rem;
  font-weight: 200;

}

.swatch-delete {

  text-transform: uppercase;
  cursor: pointer;
  /* border: 1px dashed red; */
  align-self: center;
  /* flex-basis: 1; */
  width: 25%;
  font-size: 1rem;
}

#canvasInput,
#canvasInput:focus {
  padding: 5px; 
  width: 400px;
  /* border-bottom: 1px dotted #282c34; */
  margin: 5px;
  box-sizing: border-box;
}



.bigSwatch {
  width: 100vw;
  font-size: 2rem;
  color: black;
  text-shadow: 1px 1px 2px #777, 0 0 1em white, 0 0 0.2em white;
  padding-top: 4%;
  /* padding-bottom: 1%; */
  letter-spacing: 0.7rem;
  display: block;
  /* border-bottom: 1px solid rgb(220, 220, 220); */
}

.bigSwatch-label-main {
  font-size: 3rem;
  color: rgb(32, 32, 32);
  letter-spacing: 2rem;
  animation: moveInLeft 1.5s;
  margin-bottom: 1rem;
  display: block;
  font-weight: 300;
}
.bigSwatch-label-sub {
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.6rem;
  transform: all 0.5s;
  animation: fadeIn 3s;
  color: rgb(36, 35, 35);
  display: inline-block;
  font-weight: 200;
}

a,
a:visited {
  text-decoration: none;
  display: inline-block;
  border-radius: 100px;
  transition: all .2s;
  position: relative;
}


@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  20% {
    opacity: .2;
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes coverCanvas {
  0% {
    background-color: white;
    opacity: 1;
    z-index: 1;
  }

  100% {
    opacity: 0;
  }
}

@media 
(-webkit-min-device-pixel-ratio: 2), 
(min-resolution: 192dpi) { 
  .App {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    /* justify-content: center; */
    font-family: 'Raleway', sans-serif;
    min-height: 100vh;
    min-width: 100vw;
    text-transform: lowercase;
    font-size: 62.5%;
    font-weight: 200;
  }

  .bigSwatch-label-main {
    font-size: 2.5rem;
    color: rgb(32, 32, 32);
    letter-spacing: 11px;
    animation: moveInLeft 1.5s;
    margin-bottom: 10px;
    display: block;
    font-weight: 300;
  }
  .bigSwatch-label-sub {
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: 5px;
    padding-top: 10px;
    transform: all 0.5s;
    animation: fadeIn 3s;
    color: rgb(36, 35, 35);
    display: inline-block;
    font-weight: 200;
  }
}